<template>
  <div class="subprestation-names">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.SubPrestationName"
        :create="openCreateModal"
      >
        <h1 class="title">Sous-prestations</h1>
      </page-header>

      <datatable
        ref="subPrestationNamesList"
        :fetch="$Api.SubPrestationName.fetchSubPrestationNames"
        :enableDownload="true"
        :entity="$EntitiesName.SubPrestationName"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
      </datatable>
    </div>

    <modal
      ref="createSubPrestationNameModal"
      @create="createSubPrestationName"
      title="Création d'une sous-prestation"
    >
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
          <text-field
            label="Nom"
            data-test="sel-subprestation-name-name"
            v-model="subPrestationName.name"
            :inline="false"
            :edit="true"
            required
          />
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "subprestation-names",
  data() {
    return {
      subPrestationName: {},
      columns: {
        name: {
          title: "Nom",
        },
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createSubPrestationNameModal.open();
    },
    createSubPrestationName() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post("/subprestation-names", this.subPrestationName).then((response) => {
          if (response.data.success) {
            this.subPrestationName = {
              id: "",
              name: "",
            };
            this.$refs.subPrestationNamesList.refresh();
            this.$refs.createSubPrestationNameModal.close();
            this.$router.push({
              name: "SubPrestation",
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>